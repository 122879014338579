export function sanitizeAndTruncateFields(
  data: any,
  maxDepth = 4,
  seen = new WeakSet()
): any {
  if (data === null || data === undefined) {
    return null;
  }

  if (typeof data === 'object') {
    if (seen.has(data)) {
      return null;
    }

    seen.add(data);
  }

  let ret: any = null;

  if (Array.isArray(data)) {
    ret = [];

    for (let i = 0; i < data.length; i++) {
      const value = data[i];
      const type = typeof value;
      if (
        type === 'boolean' ||
        type === 'number' ||
        type === 'bigint' ||
        type === 'string'
      ) {
        // Bool, numbers and strings are always passed through.
        ret.push(value);
      } else if (type === 'object') {
        // Objects (includes arrays) are considered a depth increment.
        const child = sanitizeAndTruncateFields(value, maxDepth - 1, seen);
        if (child !== null) {
          ret.push(child);
        }
      }
      // Everything else is ignored (functions, symbols and undefined).
    }
  } else {
    ret = {};

    for (const key of Object.keys(data)) {
      const value = data[key];
      const type = typeof value;
      if (
        type === 'boolean' ||
        type === 'number' ||
        type === 'bigint' ||
        type === 'string'
      ) {
        // Bool, numbers and strings are always passed through.
        ret[key] = value;
      } else if (type === 'object') {
        // Objects (includes arrays) are considered a depth increment.
        const child = sanitizeAndTruncateFields(value, maxDepth - 1, seen);
        if (child !== null) {
          ret[key] = child;
        }
      }
      // Everything else is ignored (functions, symbols and undefined).
    }
  }

  // Check that the result is less than 10KB
  const stringified = JSON.stringify(ret);
  if (stringified.length > 10_000) {
    return {
      truncatedEntry: stringified.slice(0, 10_000),
    };
  }

  return ret;
}
