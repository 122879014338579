/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MintJwtRequest } from '../models/MintJwtRequest';
import type { MintJwtResponse } from '../models/MintJwtResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MintJwtService {

    /**
     * @param requestBody
     * @returns MintJwtResponse
     * @throws ApiError
     */
    public static mintJwt(
        requestBody: MintJwtRequest,
    ): CancelablePromise<MintJwtResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/jwt/mint-jwt',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
