import { apolloProvider } from '@/apollo_provider';
import {
  CompleteMweSignupDocument,
  Invitations,
  Invitations_Customers,
  UpdateInvitationDocument,
  useInvitationQuery,
} from '@/generated/graphql';
import { isEmailValid } from '@/utils/email';
import _ from 'lodash';
import Vue from 'vue';

export interface MweSignup {
  token: string | null;
  existing: boolean;
  passwordChangeToken: string | null;
  tokenEntry: string;
  invitation: Invitations | null;
  loading: boolean;
  error: string | null;
  step: number;
  primaryContact: typeof startingClientData['primaryContact'];
  billingContact: typeof startingClientData['billingContact'];
  affidavit: typeof startingClientData['affidavit'];
}

const startingClientData = {
  step: 0,
  primaryContact: {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
  },
  billingContact: {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  },
  affidavit: {
    agrees_to_electronic_signature: false,
    agrees_to_affidavit: false,
    name: '',
    date: '',
    phone: '',
    title: '',
  },
};

export const MweSignupPlugin = {
  install(vue: any) {
    vue.prototype.$mweSignup = new Vue<MweSignup>({
      data() {
        const token = new URLSearchParams(location.search).get('token');
        const data: Partial<MweSignup> = {
          token,
          existing: false,
          passwordChangeToken: null,
          tokenEntry: token || '',
          invitation: null,
          loading: !!token,
          error: null,
        };

        Object.assign(data, startingClientData);

        if (token) {
          data.step = 1;
        }

        return data as MweSignup;
      },
      apolloProvider,
      apollo: {
        invitation: useInvitationQuery({
          context() {
            return {
              skipAuth: true,
              headers: {
                // The TS typing on Vue really sucks :(
                'X-Hasura-Invitation-Token': (this as any).$data.token,
              },
            };
          },
          skip() {
            return !this.$data.token;
          },
          update(data) {
            this.$data.loading = false;

            const invitation = data.invitations[0];

            if (!invitation) {
              console.error('Failed to find invitation:', this.$data.token);
              this.$data.error =
                "Woops, we couldn't find that invitation code.";
              return null;
            }

            // Client data can come from the server on the invitation
            // `client_data` field. If that field is null however, then we
            // populate it with the `startingClientData` but fill the billing
            // address info from deal `billto` data.
            let clientData = invitation.client_data;

            if (!clientData) {
              clientData = startingClientData;

              const billingAddress = _.first(
                _.first(invitation.invitations_customers)?.customer?.deals
              )?.billto_address;

              if (billingAddress) {
                clientData.billingContact.address1 =
                  billingAddress.address1 || '';
                clientData.billingContact.address2 =
                  billingAddress.address2 || '';
                clientData.billingContact.city = billingAddress.city || '';
                clientData.billingContact.state = billingAddress.state || '';
                clientData.billingContact.zip = billingAddress.zip || '';
              }
            }

            // We can assign this.data the clientData now.
            Object.assign((this as any).$data, clientData);

            if (this.$data.step === 0) {
              this.$data.step = 1;
            }

            return invitation;
          },
          error() {
            // We get confusing errors from Hasura when the token is invalid, so
            // just assume the token is invalid on error.
            console.error(
              'Failed to find invitation:',
              (this as any).$data.token
            );
            (this as any).$data.error =
              "Woops, we couldn't find that invitation code.";
          },
          fetchPolicy: 'no-cache',
        }),
      },
      computed: {
        accountsToEnroll() {
          if (
            !this.$data.invitation?.invitations_customers?.length ||
            !this.$data.invitation?.metadata?.mwe_accounts?.length
          ) {
            return [];
          }

          const customers = this.$data.invitation.invitations_customers.map(
            (ic: Invitations_Customers) => ic.customer
          );
          const mweAccounts: {
            customer_id: number;
            customer_name?: string;
            account_numbers: string[];
          }[] = this.$data.invitation?.metadata?.mwe_accounts;

          // We key off MWE accounts in the metadata, but the customer name
          // needs to be enriched (when missing) with the WRE customer name.
          for (const account of mweAccounts) {
            if (!account.customer_name) {
              account.customer_name = _.find(
                customers,
                (c) => c.id == account.customer_id
              )!.name;
            }
          }

          return mweAccounts.map((a) => ({
            id: a.customer_id,
            name: a.customer_name,
            accounts: a.account_numbers,
          }));
        },
        affidavitIsComplete() {
          const a = this.$data.affidavit;
          return (
            a.agrees_to_electronic_signature &&
            a.agrees_to_affidavit &&
            a.name &&
            a.date &&
            a.phone &&
            a.title
          );
        },
        primaryEmailIsValid() {
          return isEmailValid(this.$data.primaryContact.email);
        },
      },
      methods: {
        back(e: Event) {
          e.preventDefault();

          // Cannot go below step 1 (step 0 is the token-ask).
          if (this.$data.step > 1) {
            this.$data.step -= 1;
          }
        },
        async setToken(e: Event) {
          e.preventDefault();
          // This will be cleared in the `update` for the invitation query.
          this.$data.loading = true;
          this.$data.token = this.$data.tokenEntry;
          await this.$apollo.queries.invitation.refetch();
        },
        async continue(e: Event) {
          e.preventDefault();

          // This will be cleared in the `update` for the invitation query.
          this.$data.loading = true;

          const clientData = {
            step: this.$data.step,
            primaryContact: this.$data.primaryContact,
            billingContext: this.$data.billingContact,
            affidavit: this.$data.affidavit,
          };

          clientData.step += 1;
          await this.$apollo.mutate({
            context: {
              skipAuth: true,
              headers: {
                'X-Hasura-Invitation-Token': this.$data.token,
              },
            },
            mutation: UpdateInvitationDocument,
            variables: {
              token: this.$data.token || 'missing-token',
              client_data: clientData,
            },
          });

          await this.$apollo.queries.invitation.refetch();
        },
        async completeSignup(e: Event) {
          e.preventDefault();

          try {
            console.log('Completing MWE signup...');
            const result = await this.$apollo.query({
              query: CompleteMweSignupDocument,
              variables: {
                token: this.$data.token,
                primary_contact: this.$data.primaryContact,
                billing_contact: this.$data.billingContact,
                affidavit: this.$data.affidavit,
              },
            });

            if (result.errors) {
              this.$data.error = result.errors
                .map((e) => e.toString)
                .join(', ');
              return;
            }

            console.log('complete call response');
            console.log(result.data);

            this.$data.existing = result.data.completeMweSignup.existing;
            this.$data.passwordChangeToken =
              result.data.completeMweSignup.ticket;
            this.$data.step += 1;
          } catch (e: any) {
            this.$data.error = e.toString();
          }
        },
        async finish(e: Event) {
          e.preventDefault();
          window.location.href = this.$data.passwordChangeToken;
        },
      },
    });
  },
};
