import Vue from 'vue';

/// Like `new Date(...).toDateString()` except the date will be loaded as if it
/// were already in the local timezone. By default `new Date(...)` always treats
/// the date string as UTC, which is rarely what we want for date-only strings.
Vue.filter('date-string', (value: any) => {
  const date = parseDate(value);

  if (!date) {
    return 'Invalid date';
  }

  return date.toDateString();
});

/// Parse a date or timestamp from Hasura into a Date object.
///
/// Dates and Timestamps in the DB are stored without timezones. Rails stores
/// dates in UTC by default, and Hasura's `now()` function is also in UTC,
/// thankfully. However, the string formats don't include the "Z" to denote UTC,
/// so the Date constructor treats them as if they were local, which is wrong.
/// To add to the mess, dates (without times) are actually in 'local' timezones,
/// in that Rails doesn't convert to UTC first.
export function parseDate(date: string): Date | null {
  if (!date || typeof date !== 'string' || date === '') {
    return null;
  }

  const b = date.split(/\D/).map((s) => parseInt(s));

  if (b.length === 1) {
    // Year-only format, although I don't think this is actually used.
    return new Date(b[0], 0, 1);
  }

  if (b.length === 3) {
    // Treat the date as 'local'. The Date constructor expects date numbers to
    // be zero-indexed for some weird reason, so we have to subtract one from
    // the month number.
    return new Date(b[0], b[1] - 1, b[2]);
  }

  // Some of our strings have a +00:00 at the end to indicate timezone, in which
  // case we do not want to append a Z.
  if (/\+\d+\:\d+$/.test(date)) {
    return new Date(date);
  }

  // Try to treat it as a string and let the Date constructor parse it. We need
  // to tack a "Z" onto the end.
  if (!date.toLowerCase().endsWith('z')) {
    date = date + 'Z';
  }

  return new Date(date);
}
