import { authProvider } from '@/auth/provider';
import { CustomersInfoPlugin } from '@/plugins/customers_info';
import { blinkCreateOneTimeAch } from '@/utils/blink';
import { isNaN } from 'lodash';
import Vue from 'vue';

export interface OneTimeAch {
  amountUsd: string;
  routingNumber: string;
  accountNumber: string;
  agreesToTerms: boolean;
  loading: boolean;
  error: string | null;
}

export const OneTimeAchPlugin = {
  install(vue: any) {
    Vue.use(CustomersInfoPlugin);
    vue.prototype.$oneTimeAch = new Vue<OneTimeAch>({
      data() {
        return {
          amountUsd: '',
          routingNumber: '',
          accountNumber: '',
          agreesToTerms: false,
          loading: false,
          error: null,
        } as OneTimeAch;
      },
      computed: {
        canSubmit() {
          return (
            !this.loading &&
            !isNaN(Number.parseFloat(this.amountUsd)) &&
            this.routingNumber &&
            this.accountNumber &&
            this.agreesToTerms
          );
        },
      },
      methods: {
        async createOneTimeAch(e: Event) {
          e.preventDefault();

          this.error = null;
          const amount = Number.parseFloat(this.amountUsd);

          if (isNaN(amount) || amount <= 0) {
            this.error = 'Amount must be a positive value';
            return;
          }

          // Client-side validation
          if (!/^\d{9}$/.test(this.routingNumber)) {
            this.error = 'Routing number must be 9 digits (and only digits)';
            return;
          }

          if (!/^\d+$/.test(this.accountNumber)) {
            this.error = 'Account number must be only digits';
            return;
          }

          const customerId = this.$customersInfo.customer?.id;
          const user = authProvider.user;

          if (!customerId || !user) {
            throw 'Missing customer id or user in createOneTimeAch call';
          }

          // This doesn't work for some time zones. Don't feel like fixing it though,
          // won't cause issues on the backend.
          const tomorrow = new Date()
          tomorrow.setDate(tomorrow.getDate() + 1)

          this.loading = true;

          const error = await blinkCreateOneTimeAch({
            achAccount: {
              accountNumber: this.accountNumber,
              routingNumber: this.routingNumber,
            },
            centsUsd: Math.round(amount * 100).toString(),
            customerId: customerId.toString(),
            paymentDue: tomorrow.toISOString(),
          })

          // Success case
          if (error == null) {
            window.location.pathname = '/portal/one-time-payment-success';
          } else {
            this.error = error;
          }

          this.loading = false;
        },
      },
    });
  },
};
