/// Returns a param from the URL hash. Handles url decoding the value.
///
/// ## Example
/// ```ts
/// // For the URL: example.com/foo#some=42&other=thing
/// getHashParam('some'); // => '42'
/// getHashParam('dne'); // => null
/// ```
export function getHashParams(param: string): string | null {
  const part = window.location.hash
    .split('#')
    .flatMap((s) => s.split('&'))
    .filter((s) => s)
    .find((s) => s.includes(`${param}=`));

  if (!part) {
    return null;
  }

  return decodeURIComponent(part.substring(param.length + 1));
}
