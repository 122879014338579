import Vue from 'vue';
import ApolloClient from 'apollo-boost';
import VueApollo from 'vue-apollo';
import { ServerError } from 'apollo-link-http-common';

import { config, getPageConfig } from '@/config';
import { authProvider } from '@/auth/provider';
import { logError } from '@/logger';

/// The apollo client all applications will use.
export const apolloClient = new ApolloClient({
  uri: config.apiUrl,

  onError(error) {
    const netError = error.networkError as ServerError;

    // Handle unauthorized requests by logging out and sending the user to the
    // login page.
    if (netError && netError.statusCode === 401) {
      authProvider.execLoginWithRedirect(window.location.href);
    } else {
      logError('apollo client error', { error });
    }
  },

  // Auth middleware
  async request(operation) {
    await authProvider.isCompletePromise;
    if (
      (await getPageConfig()).requiresAuth &&
      (!authProvider.isAuthenticated || !authProvider.token)
    ) {
      await authProvider.execLoginWithRedirect(window.location.href);

      // Never return
      await new Promise(() => {});
    }

    // TODO: This doesn't work. And I have no idea why.
    const context = { ...operation.getContext() };
    const skipAuth = context.skipAuth;

    if (!skipAuth && authProvider.token) {
      const headers = { authorization: `Bearer ${authProvider.token}` };
      operation.setContext({ headers });
    }
  },
});
(window as any).apolloClient = apolloClient;

Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
