window.Webflow = window.Webflow || [];
window.Webflow.push(() => {
  $('form').each(function () {
    if ($(this).attr('webflow-default')) {
      return;
    }

    $(this).off('submit');
    $(this).submit((e) => e.preventDefault());
  });
});
