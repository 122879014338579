// Source (cleaned up and modernized):
// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export function hashCode(str: string) {
  let hash = 0;

  if (!str.length) {
    return hash;
  }

  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return hash;
}

export function formatNumberWithCommas(
  x: number | string,
  fixed: number
): string {
  // A bit weird, but correctly handles null, undefined and NaN strings.
  const num = Number(String(x));
  if (isNaN(num)) {
    return "NaN";
  }

  const [integerPart, ..._] = num.toString().split(".");

  // Insert commas into the integer part, at the thousand markers.
  const integerPartCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // If fixed is greater then 0, we also need to convert the decimal part to a
  // fixed sig-fig.
  if (fixed > 0) {
    const [_, decimalStr] = num.toFixed(fixed).split(".");
    return `${integerPartCommas}.${decimalStr}`;
  }

  return integerPartCommas;
}

export function isValidEmailString(email: string): boolean {
  if (typeof email !== "string") {
    return false;
  }

  // From:
  // https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
  return !!email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function isFloat(val: any) {
  val = String(val);
  const floatRegex = /^-?\d*(?:[.,]\d*?)?$/;
  if (!floatRegex.test(val)) {
    return false;
  }

  val = parseFloat(val);
  if (isNaN(val)) {
    return false;
  }
  return true;
}

export function isInt(val: any) {
  val = String(val).trim();
  const intRegex = /^-?\d+$/;
  if (!intRegex.test(val)) {
    return false;
  }

  const intVal = parseInt(val, 10);
  return parseFloat(val) == intVal && !isNaN(intVal);
}

export function isUrl(str: any) {
  return /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*$)/.test(
    String(str)
  );
}
