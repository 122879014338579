import { authProvider } from '@/auth/provider';
import { config } from '@/config';
import { InsertLogEntriesDocument } from '@/generated/graphql';
import { StandardLogDataFormat } from '@/logger';
import { decrementRemaining, getAllLogs, pushLog } from '@/logger/session';
import ApolloClient from 'apollo-boost';
import _ from 'lodash';

const apolloClient = new ApolloClient({
  uri: config.apiUrl,
  request: async (operation) => {
    const headers = authProvider.token
      ? { authorization: `Bearer ${authProvider.token}` }
      : {};
    operation.setContext({ headers });
  },
  onError(_error) {
    // Do nothing.
  },
});

export function enqueueLog(data: StandardLogDataFormat) {
  try {
    if (decrementRemaining() <= 0) {
      return;
    }

    pushLog(data);
    throttledTransmit();
  } catch (_e) {
    // Nothing interesting to do with errors at this point.
  }
}

async function transmitEntries() {
  const entries = getAllLogs();

  if (!entries.length) {
    return;
  }

  const log_entries = entries.map((data) => ({ data }));

  try {
    await apolloClient.mutate({
      mutation: InsertLogEntriesDocument,
      variables: { log_entries },
    });
  } catch (_e) {
    // There isn't much to be done with errors, it's not like we can log them
    // anywhere that they aren't already.
  }
}

/// Will only transmit logs every 2 seconds, with the first entry being delayed
/// 2s.
const throttledTransmit = _.throttle(transmitEntries, 2_000, {
  leading: false,
  trailing: true,
});

/// Start initial transmit now, as some logs might be queued from SessionStorage
throttledTransmit();
