import { apolloProvider } from '@/apollo_provider';
import {
  Addresses,
  Balance_Dues,
  useBillingInfoQuery,
} from '@/generated/graphql';
import { CustomersInfoPlugin } from '@/plugins/customers_info';
import { valueOrNull } from '@/utils/obj_access';
import Vue from 'vue';

export interface BillingInfo {
  balance_due: Balance_Dues | null;
  billto_address: Addresses | null;
}

export const BillingInfoPlugin = {
  install(vue: any) {
    Vue.use(CustomersInfoPlugin);
    vue.prototype.$billingInfo = new Vue<BillingInfo>({
      data() {
        return {
          balance_due: null,
          billto_address: null,
        } as BillingInfo;
      },
      apolloProvider,
      apollo: {
        balance_due: useBillingInfoQuery({
          variables() {
            return { customer_id: this.$customersInfo!.customer!.id };
          },
          skip() {
            return !this.$customersInfo.customer;
          },
          update(data) {
            // Redirect Billing->Reports if the customer is EEG.
            const isEeg =
              this.$customersInfo.customer?.company?.description ===
              'Education Energy Group';

            if (isEeg && window.location.pathname === '/portal/billing') {
              const url = new URL(window.location.href);
              url.pathname = '/portal/reports';
              window.location.href = url.toString();
              return null;
            }

            return valueOrNull(() => data.balance_dues[0]);
          },
          fetchPolicy: 'no-cache',
        }),
        billto_address: useBillingInfoQuery({
          variables() {
            return { customer_id: this.$customersInfo!.customer!.id };
          },
          skip() {
            return !this.$customersInfo.customer;
          },
          update(data) {
            return valueOrNull(() => data.deals[0].billto_address);
          },
          fetchPolicy: 'no-cache',
        }),
      },
      computed: {
        balance() {
          return this.balance_due?.amount_due || 0;
        },
      },
    });
  },
};
