import { apolloProvider } from '@/apollo_provider';
import {
  InvoiceFileUrlDocument,
  Invoice_Numbers,
  useCustomerInvoiceDataQuery,
} from '@/generated/graphql';
import { CustomersInfoPlugin } from '@/plugins/customers_info';
import _ from 'lodash';
import Vue from 'vue';

export interface AdditionalInvoiceData {
  bill_date: string;

  /// Set to true when the async callback for getting an invoice file URl is set
  /// to true.
  loading: boolean;

  /// Value is set when an invoice file URL has been fetched. This is used to
  /// replace the 'open' button with a standard <a> tag, which is much less
  /// likely to be blocked by some crazy user extension. The value will be
  /// removed automatically in a 5 min timer.
  invoice_file_url: string | null;
}

export interface CustomerInvoice {
  invoice_numbers: (Invoice_Numbers & AdditionalInvoiceData)[];
}

export const CustomerInvoicesPlugin = {
  install(vue: any) {
    Vue.use(CustomersInfoPlugin);
    vue.prototype.$customerInvoices = new Vue<CustomerInvoice>({
      data() {
        return {
          invoice_numbers: [],
        } as CustomerInvoice;
      },
      apolloProvider,
      apollo: {
        invoice_numbers: useCustomerInvoiceDataQuery({
          variables() {
            return {
              customer_id: this.$customersInfo!.customer!.id,
            };
          },
          skip() {
            return !this.$customersInfo.customer;
          },
          update(data) {
            return _(data.invoice_numbers)
              .orderBy('accounting_mth', 'desc')
              .map((invoice) => ({
                ...invoice,
                bill_date: _.first(invoice.invoice_data)?.invoice_date,
                loading: false,
                invoice_file_url: null,
              }))
              .value();
          },
          fetchPolicy: 'no-cache',
        }),
      },
      methods: {
        async openInvoicePdf(invoice: Invoice_Numbers & AdditionalInvoiceData) {
          if (!invoice || !invoice.invoice_number) {
            throw new Error(
              'Cannot fetch malformed invoice: ' + JSON.stringify(invoice)
            );
          }

          if (invoice.loading) {
            return;
          }

          invoice.loading = true;
          invoice.invoice_file_url = null;

          // If we open the window after loading the URL then Safari can't track
          // the fact that it originated from a user action and will block the
          // "popup" by default. Safari is such a hunk of garbage. This is a
          // workaround to that problem, open the window now but replace the
          // page URL after loading the invoice file URL.
          const windowReference = window.open();

          const fileUrl = await this.$apollo.query({
            query: InvoiceFileUrlDocument,
            variables: {
              invoice_number: invoice.invoice_number,
            },
            // URLs expire, so never cache them.
            fetchPolicy: 'no-cache',
          });

          invoice.loading = false;
          invoice.invoice_file_url =
            fileUrl.data.invoice_numbers[0].invoice_file_url;

          // Clear the invoice file url in 5 min.
          setTimeout(() => {
            invoice.invoice_file_url = null;
          }, 1_000 * 60 * 5);

          if (
            windowReference &&
            windowReference.location &&
            typeof windowReference.location.assign === 'function'
          ) {
            windowReference.location.assign(invoice.invoice_file_url!);
          }
        },
      },
    });
  },
};
