import { apolloProvider } from '@/apollo_provider';
import { Customers, useCustomersInfoQuery } from '@/generated/graphql';
import { logError } from '@/logger';
import _ from 'lodash';
import Vue from 'vue';

const WRE_SELECTED_CUSTOMER_UUID = 'WRE_DEFAULT_CUSTOMER';

export interface CustomersInfo {
  customers: Customers[];
  customer: Customers | null;
}

export const CustomersInfoPlugin = {
  install(vue: any) {
    vue.prototype.$customersInfo = new Vue<CustomersInfo>({
      data() {
        return {
          customers: [],
          customer: null,
        } as CustomersInfo;
      },
      apolloProvider,
      apollo: {
        customers: useCustomersInfoQuery({
          fetchPolicy: 'no-cache',
        }),
      },
      watch: {
        customers(customers: Customers[]) {
          if (!this.customer) {
            const uuid = localStorage.getItem(WRE_SELECTED_CUSTOMER_UUID);
            this.customer =
              customers.find((c) => c.uuid === uuid) ||
              customers.find((_) => true) ||
              null;
          }
        },
        customer(customer: Customers | null) {
          if (customer) {
            localStorage.setItem(WRE_SELECTED_CUSTOMER_UUID, customer.uuid!);
          }
        },
      },
      computed: {
        customerContacts() {
          return _.flatMap(
            this.customer?.contact_assignments || [],
            (ca) => ca.contact_entry
          );
        },
      },
      methods: {
        setCustomerId(customer_id: string | number) {
          const customer = this.customers.find(
            (c) => c.id === Number.parseInt(customer_id.toString())
          );
          if (customer) {
            this.customer = customer;
          } else {
            logError(`failed to activate customer id ${customer_id}`, {
              availableCustomers: this.customers.map((c) => ({
                name: c.name,
                id: c.id,
              })),
            });
          }
        },
      },
    });
  },
};
