import Vue from 'vue';
import { User } from '@auth0/auth0-spa-js';

import { initAuth0Provider } from '@/auth/auth0_provider';
import {
  getJwtOverride,
  initJwtOverrideProvider,
} from '@/auth/jwt_override_provider';

export interface AuthProvider {
  isCompletePromise: Promise<void>;
  isComplete: boolean;
  isAuthenticated: boolean;
  isImpersonating: boolean;
  user: User;
  token: string | null;
  execLoginWithRedirect: (redirectUrl?: string) => Promise<void>;
  execLogout: () => Promise<void>;
}

export let authProvider: AuthProvider;

export function initAuth() {
  const jwtOverride = getJwtOverride();

  if (!!jwtOverride) {
    // Use the JWT override provider.
    authProvider = initJwtOverrideProvider(jwtOverride);
  } else {
    // Use the standard Auth0 provider.
    authProvider = initAuth0Provider();
  }

  (window as any).auth = authProvider;
}

/// The Auth0 Vue plugin (for access from within Vue apps).
export const AuthPlugin = {
  install(vue: any) {
    vue.prototype.$auth = new Vue({
      data() {
        return authProvider;
      },
      methods: {
        loginWithRedirect(e: Event) {
          e.preventDefault();
          e.stopPropagation();
          authProvider.execLoginWithRedirect();
        },
        logout(e: Event) {
          e.preventDefault();
          e.stopPropagation();
          authProvider.execLogout();
        },
      },
    });
  },
};
