import Vue from 'vue';
import { sanitizeAndTruncateFields } from '@/logger/sanitize';
import { getSessionId } from '@/logger/session';
import { enqueueLog } from '@/logger/transmitter';

export interface StandardLogDataFormat {
  /// Scope depends on the application.
  session_id?: string;

  /// The overall application that generated this log entry.
  application?: string;

  /// When a log entry is too large, it is serialized to JSON, truncated and
  /// wrapped in another entry. This string contains the wrapped entry.
  truncatedEntry?: string;

  /// Human readable string that summarizes the entry.
  message?: string;

  /// Set only if the entry is a non-info severity.
  severity?: 'warn' | 'error';

  // Any other field can be attached. The above are just standardized fields.
}

export const logConfig = {
  /// The application name.
  application: 'customer-portal',

  /// Max number of logs that can be sent per unique session.
  maxLogsPerSession: 100,
};

const consoleLog = console.log.bind(console);
const consoleWarn = console.warn.bind(console);
const consoleError = console.error.bind(console);

export async function logInfo(
  message: string,
  additionalFields: { [key: string]: any } = {}
) {
  enqueueLog({
    session_id: getSessionId(),
    application: logConfig.application,
    message,
    ...sanitizeAndTruncateFields(additionalFields),
  });
  consoleLog(message, additionalFields);
}

export function logWarn(
  message: string,
  additionalFields: { [key: string]: any } = {}
) {
  enqueueLog({
    session_id: getSessionId(),
    application: logConfig.application,
    message,
    severity: 'warn',
    ...sanitizeAndTruncateFields(additionalFields),
  });
  consoleWarn(message, additionalFields);
}

export function logError(
  message: string,
  additionalFields: { [key: string]: any } = {}
) {
  enqueueLog({
    session_id: getSessionId(),
    application: logConfig.application,
    message,
    severity: 'error',
    ...sanitizeAndTruncateFields(additionalFields),
  });
  consoleError(message, additionalFields);
}

// Hook all console functions and forward them.
console.log = (...args: any[]) => {
  enqueueLog({
    session_id: getSessionId(),
    application: logConfig.application,
    message: 'console.log',
    ...sanitizeAndTruncateFields({ args }),
  });
  consoleLog(...args);
};

console.warn = (...args: any[]) => {
  enqueueLog({
    session_id: getSessionId(),
    application: logConfig.application,
    message: 'console.warn',
    severity: 'warn',
    ...sanitizeAndTruncateFields({ args }),
  });
  consoleWarn(...args);
};

console.error = (...args: any[]) => {
  enqueueLog({
    session_id: getSessionId(),
    application: logConfig.application,
    message: 'console.error',
    severity: 'error',
    ...sanitizeAndTruncateFields({ args }),
  });
  consoleError(...args);
};

// Unhandled window errors
window.onerror = (message, file, line, col, error) => {
  consoleLog(message, file, line, col, error);
  enqueueLog({
    session_id: getSessionId(),
    application: logConfig.application,
    message: 'unhandled exception',
    severity: 'error',
    ...sanitizeAndTruncateFields({ message, file, line, col, error }),
  });
  return false;
};

// Unhandled promise errors
try {
  window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled promise rejection:', event.reason);
  });
} catch (_) {
  console.error('Failed to register unhandledrejection handler');
}

// Unhandled Vue errors
Vue.config.errorHandler = (err, _vm, info) => {
  console.error(info, err);
};
