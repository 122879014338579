import Vue from 'vue';

/// Change the display mode of an element to 'none' when the condition is false,
/// or to 'block' when the condition is true.
Vue.directive('display', (el, bind) => {
  el.style.display = !!bind.value ? 'block' : 'none';
});

/// Customer directive to handle Webflow button disabling.
Vue.directive('disabled', (el, bind) => {
  if (!!bind.value) {
    el.classList.add('disabled');
    el.setAttribute('disabled', 'true');
  } else {
    el.classList.remove('disabled');
    el.removeAttribute('disabled');
  }
});
