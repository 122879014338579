import { apolloProvider } from '@/apollo_provider';
import {
  Invoice_Numbers,
  useCustomerInvoiceDataQuery,
} from '@/generated/graphql';
import { CustomersInfoPlugin } from '@/plugins/customers_info';
import _ from 'lodash';
import Vue from 'vue';

export interface Reports {
  invoice_numbers: Invoice_Numbers[];
}

export const ReportsPlugin = {
  install(vue: any) {
    Vue.use(CustomersInfoPlugin);
    vue.prototype.$reports = new Vue<Reports>({
      data() {
        return { invoice_numbers: [] } as Reports;
      },
      apolloProvider,
      apollo: {
        invoice_numbers: useCustomerInvoiceDataQuery({
          variables() {
            return {
              customer_id: this.$customersInfo!.customer!.id,
            };
          },
          skip() {
            return !this.$customersInfo.customer;
          },
          fetchPolicy: 'no-cache',
        }),
      },
      computed: {
        // Returns usage per-meter, as an array of objects, each representing
        // a premise, each with a 'monthSum' and 'yearSum' array of summed dth
        // and ext values by month/year respectively.
        usage_by_premise() {
          return (
            _(this.invoice_numbers)
              // Select only invoice_data (we don't care about invoices)
              .flatMap((i) => i.invoice_data)
              // Group them all by service id.
              .groupBy((id) => id.service_id)
              .values()
              .map((invoiceDataByPremise) => {
                // Use the latest invoice data for account, premise, et. al.
                const latestInvoiceData = _(invoiceDataByPremise)
                  .orderBy('accounting_month', 'desc')
                  .first()!;

                return {
                  ddId: latestInvoiceData.dd_id,
                  pipeline: latestInvoiceData.pipeline,
                  utility: latestInvoiceData.ldc,
                  accountNumber: latestInvoiceData.deal_detail?.account_number,
                  premiseNumber: latestInvoiceData.premise_number,
                  serviceAddress: latestInvoiceData.service_address,
                  monthSums: _(invoiceDataByPremise)
                    .groupBy((dd) => dd.accounting_month)
                    .map((ddByMonth) => ({
                      accountingMonth: ddByMonth[0].accounting_month,
                      dthTotal: _.sumBy(ddByMonth, 'dth') || 0,
                      extTotal: _.sumBy(ddByMonth, 'ext') || 0,
                    }))
                    .orderBy('accountingMonth', 'desc')
                    .value(),
                  yearSums: _(invoiceDataByPremise)
                    .groupBy((dd) =>
                      new Date(dd.accounting_month).getUTCFullYear()
                    )
                    .map((ddByMonth) => ({
                      year: new Date(
                        ddByMonth[0].accounting_month
                      ).getUTCFullYear(),
                      dthTotal: _.sumBy(ddByMonth, 'dth') || 0,
                      extTotal: _.sumBy(ddByMonth, 'ext') || 0,
                    }))
                    .orderBy('year', 'desc')
                    .value(),
                };
              })
              // Enforce a consistent (if arbitrary) order to premises.
              .orderBy('service_id', 'desc')
              .value()
          );
        },
      },
    });
  },
};
