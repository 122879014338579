/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChoiceEmailQuote } from '../models/ChoiceEmailQuote';
import type { CreateQuoteRequest } from '../models/CreateQuoteRequest';
import type { CreateQuoteSelection } from '../models/CreateQuoteSelection';
import type { CreateQuoteSelectionResponse } from '../models/CreateQuoteSelectionResponse';
import type { FindQuoteRequest } from '../models/FindQuoteRequest';
import type { QuoteResponse } from '../models/QuoteResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChoiceService {

    /**
     * @param requestBody
     * @returns QuoteResponse
     * @throws ApiError
     */
    public static createQuote(
        requestBody: CreateQuoteRequest,
    ): CancelablePromise<QuoteResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/choice/create-quote',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns CreateQuoteSelectionResponse
     * @throws ApiError
     */
    public static createSelection(
        requestBody: CreateQuoteSelection,
    ): CancelablePromise<CreateQuoteSelectionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/choice/create-selection',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static emailQuote(
        requestBody: ChoiceEmailQuote,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/choice/email-quote',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns QuoteResponse
     * @throws ApiError
     */
    public static findQuote(
        requestBody: FindQuoteRequest,
    ): CancelablePromise<QuoteResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/choice/find-quote',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
