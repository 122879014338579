export function isEmailValid(email: string): boolean {
  if (typeof email !== 'string') {
    return false;
  }

  // From:
  // https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
  return !!email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
