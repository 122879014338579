import Vue from 'vue';

import { apolloProvider } from '@/apollo_provider';
import { Dealmakers, useDealmakersInfoQuery } from '@/generated/graphql';
import { authProvider } from '@/auth/provider';

export interface DealmakersInfo {
  dealmakers: Dealmakers[];
}

export const DealmakersInfoPlugin = {
  install(vue: any) {
    vue.prototype.$dealmakersInfo = new Vue<DealmakersInfo>({
      data() {
        return {
          dealmakers: [],
        } as DealmakersInfo;
      },
      apolloProvider,
      apollo: {
        dealmakers: useDealmakersInfoQuery({
          fetchPolicy: 'no-cache',
          skip() {
            return !authProvider.isAuthenticated;
          },
        }),
      },
    });
  },
};
