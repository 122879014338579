/// Manual API calls to Blink, instead of using Protobuf because this repo is
// stuck on Node 14 and Connect won't build on Node 14, and this repo is EOL,
// so fuck it all. NUKE IT FROM ORBIT.
import { authProvider } from "@/auth/provider";

export interface AchPullRequest {
  achAccount: {
    accountNumber: string;
    routingNumber: string;
  },
  centsUsd: string;
  customerId: string;
  paymentDue: string;
}

const blinkUrl = window.location.host.startsWith("stage")
  ? "https://blink-stage.dokku.woodriverenergy.com"
  : "https://blink-prod.dokku.woodriverenergy.com";

export async function blinkCreateOneTimeAch(pull: AchPullRequest): Promise<string | null> {
  // POST: http://localhost:8080/blink.AchService/CreateOneTimeAch
  // authorization header
  const url = new URL(blinkUrl);
  url.pathname = "blink.AchService/CreateOneTimeAchExternal";
  const token = authProvider.token || undefined;

  try {
    const response = await fetch(url.href, {
      method: "POST",
      headers: {
        "authorization": `Bearer ${token}`,
        "content-type": "application/json",
      },
      body: JSON.stringify({ "achPull": pull }),
    });

    if (response.ok) {
      return null;
    } else {
      console.error("Create ACH failed:", response);
      return "Woops, something went wrong. Please call customer support!";
    }
  } catch (e) {
    return "Woops, something went wrong. Please call customer support!";
  }
}
