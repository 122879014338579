import { apolloProvider } from '@/apollo_provider';
import { authProvider } from '@/auth/provider';
import { UpdateAchInfoDocument } from '@/generated/graphql';
import { CustomersInfoPlugin } from '@/plugins/customers_info';
import Vue from 'vue';

export interface UpdateAchData {
  accountType: string;
  nameOnAccount: string;
  bankName: string;
  routingNumber: string;
  accountNumber: string;
  signatureText: string;
  agreesToTerms: boolean;
  loading: boolean;
  error: string | null;
}

export const UpdateAchPlugin = {
  install(vue: any) {
    Vue.use(CustomersInfoPlugin);
    vue.prototype.$updateAch = new Vue<UpdateAchData>({
      data() {
        return {
          accountType: 'checking',
          nameOnAccount: '',
          bankName: '',
          routingNumber: '',
          accountNumber: '',
          signatureText: '',
          agreesToTerms: false,
          loading: false,
          error: null,
        } as UpdateAchData;
      },
      apolloProvider,
      computed: {
        canSubmit() {
          return (
            !this.loading &&
            (this.accountType === 'checking' ||
              this.accountType === 'savings') &&
            this.nameOnAccount &&
            this.bankName &&
            this.routingNumber &&
            this.accountNumber &&
            this.signatureText &&
            this.agreesToTerms
          );
        },
      },
      methods: {
        async updateAch(e: Event) {
          e.preventDefault();

          this.error = null;

          // Client-side validation
          if (!/^\d{9}$/.test(this.routingNumber)) {
            this.error = 'Routing number must be 9 digits (and only digits)';
            return;
          }

          if (!/^\d+$/.test(this.accountNumber)) {
            this.error = 'Account number must be only digits';
            return;
          }

          const customerId = this.$customersInfo.customer?.id;
          const user = authProvider.user;

          if (!customerId || !user) {
            throw 'Missing customer id or user in updateAch call';
          }

          this.loading = true;

          try {
            const resp = await this.$apollo.mutate({
              mutation: UpdateAchInfoDocument,
              variables: {
                customerId: customerId,
                routingNumber: this.routingNumber,
                accountNumber: this.accountNumber,
                accountType: this.accountType,
                nameOnAccount: this.nameOnAccount,
                bankName: this.bankName,
                signatureText: this.signatureText,
                autoPay: true,
              },
            });

            // Success case
            if (resp.data.updateAchInfo === 'ok') {
              window.location.pathname = '/portal/billing';
              return;
            }
          } catch (e: any) {
            // TODO: Clean this up. It's a nested error that is ugly.
            this.error = String(e);
            this.loading = false;
            return;
          }

          this.loading = false;
          this.error = 'Something went wrong. Please contact customer support.';
        },
      },
    });
  },
};
