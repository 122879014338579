import { logConfig, StandardLogDataFormat } from '@/logger';
import { uuid } from '@/logger/uuid';

const SID = 'WRE_SESSION_ID';
const REM = 'WRE_REMAINING_LOG_ENTRIES';
const LOGS = 'WRE_PENDING_LOG_ENTRIES';

export function getSessionId(): string {
  const sessionId = sessionStorage.getItem(SID) || uuid();
  sessionStorage.setItem(SID, sessionId);
  return sessionId;
}

/// Returns the number of remaining before decrementing it.
export function decrementRemaining(): number {
  let remaining = JSON.parse(
    sessionStorage.getItem(REM) || logConfig.maxLogsPerSession.toString()
  );

  sessionStorage.setItem(
    REM,
    JSON.stringify(remaining > 0 ? remaining - 1 : 0)
  );

  return remaining;
}

// Rehydrate logs from SessionStorage
const logs = JSON.parse(sessionStorage.getItem(LOGS) || '[]');

export function pushLog(data: StandardLogDataFormat) {
  logs.push(data);
  sessionStorage.setItem(LOGS, JSON.stringify(logs));
}

export function getAllLogs(): StandardLogDataFormat[] {
  const entries = logs.splice(0, logs.length);
  sessionStorage.removeItem(LOGS);
  return entries;
}
