<script>
// I don't know how to get TS to be happy with things like this.$auth. I'm
// incredibly unimpressed with Vue's TS support, that's for sure.
import "@/auth/provider";
import { authProvider } from "@/auth/provider";
import { currency } from "@/filters/money";
import {
  ApiError,
  OpenAPI,
  PaymentType,
  StripeService,
} from "@/generated/blink";
import "@/plugins/billing_info";
import "@/plugins/customers_info";

// See: https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
// This needs to be kept in sync with the Blink equivalent.
function computeCreditCardFee(amountUsCents) {
  // 30c flat fee.
  const FLAT_FEE = 30.0;
  // 2.9% fee.
  const PERCENT_FEE = 0.029;
  // Amount after the percent fee is applied, plus the flat fee.
  const goal = amountUsCents * PERCENT_FEE + FLAT_FEE;
  // Gross up the amount to cover the fee-fee.
  const grossUp = goal / (1.0 - PERCENT_FEE);
  // The fee to charge through to the customer.
  return Math.round(grossUp);
}

// See: https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
// This needs to be kept in sync with the Blink equivalent.
function computeAchFee(amountUsCents) {
  // 0.8% with a $5 max.
  const PERCENT_FEE = 0.008;
  // Amount after the percent fee is applied.
  const goal = amountUsCents * PERCENT_FEE;
  // Gross up the amount.
  const grossUp = goal / (1.0 - PERCENT_FEE);
  // Cap it at $5 (cap is Stripe-side, so it's after the gross-up).
  return Math.min(Math.round(grossUp), 500);
}

export default {
  data() {
    return {
      customAmount: (this.$billingInfo.balance_due || {}).amount_due || 0,
      paymentMethod: "card",
      paymentAmount: "balance",
      acceptsTerms: false,
      error: null,
    };
  },
  methods: {
    async proceed() {
      try {
        OpenAPI.TOKEN = authProvider.token || undefined;

        const result = await StripeService.stripeCheckout({
          amount_us_cents: Math.round(this.subtotal * 100),
          customer_id: this.$customersInfo.customer.id,
          payment_type:
            this.paymentMethod === "ach" ? PaymentType.ACH : PaymentType.CARD,
          success_url:
            "https://www.woodriverenergy.com/portal/one-time-payment-success",
          cancel_url: "https://www.woodriverenergy.com/portal/one-time-payment",
        });

        // Navigate to Stripe.
        location.href = result.url;
      } catch (e) {
        console.error(
          "Failed to call stripe checkout.",
          "Error:",
          e,
          "JWT:",
          OpenAPI.TOKEN
        );

        if (e instanceof ApiError && (e.status === 401 || e.status === 403)) {
          // Session likely expired, refresh the page.
          location.reload();
          return;
        }
        this.error = `Woops, something went wrong: ${e}.`;
      }
    },
  },
  filters: {
    currency,
  },
  computed: {
    balance() {
      return (this.$billingInfo.balance_due || {}).amount_due || 0;
    },
    subtotal() {
      return this.paymentAmount === "balance"
        ? (this.$billingInfo.balance_due || {}).amount_due || 0
        : Number.parseFloat(this.customAmount) || 0;
    },
    fee() {
      const subtotalCents = Math.round(this.subtotal * 100);
      const feeCents =
        this.paymentMethod === "ach"
          ? computeAchFee(subtotalCents)
          : computeCreditCardFee(subtotalCents);
      return feeCents / 100;
    },
    total() {
      // @ts-ignore
      return this.subtotal + this.fee;
    },
    proceedDisabled() {
      return (
        (this.paymentMethod === "ach" && !this.acceptsTerms) ||
        // @ts-ignore
        this.subtotal <= 0
      );
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="side-by-side">
      <!-- Payment amount selection -->
      <div class="section" v-if="paymentMethod === 'card'">
        <h3>How much would you like to pay?</h3>
        <label for="balance">
          <input type="radio" id="balance" value="balance" v-model="paymentAmount" />
          Full balance due: {{ balance | currency }}
        </label>
        <label for="custom">
          <input type="radio" id="custom" value="custom" v-model="paymentAmount" />
          Custom amount
          <input v-model="customAmount" v-disabled="paymentAmount !== 'custom'" type="number" min="0.01" step="0.01" />
        </label>
      </div>

      <!-- Payment Summary, shown to the right -->
      <div class="payment-summary">
        <h3>Payment Summary</h3>
        <table>
          <tr>
            <th>Item</th>
            <th>Amount</th>
          </tr>
          <tr>
            <td>Subtotal</td>
            <td>{{ subtotal | currency }}</td>
          </tr>
          <tr>
            <td>Convenience Fee</td>
            <td>{{ fee | currency }}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{{ total | currency }}</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- Terms accept and pay button -->
    <div class="section" v-if="paymentMethod === 'card'">
      <button v-disabled="proceedDisabled" v-on:click="proceed" class="one-time-payment-submit">
        Pay with Stripe
      </button>
    </div>

    <h3 class="error-message" v-show="error">{{ error }}</h3>
  </div>
</template>

<style>
label {
  display: block;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  width: 100%;
}

.side-by-side {
  display: flex;
  flex-direction: row;
}

.section {
  margin-bottom: 10px;
}

.payment-summary {
  background-color: #f2f2f2;
  border-radius: 4px;
  margin-left: 10px;
  padding: 10px;
}

.notice-text {
  max-width: 70%;
  margin-top: 40px;
}

table,
th,
td {
  padding: 4px 10px;
}

table tr:last-child {
  border-top: 1px solid black;
}

.one-time-payment-submit {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: auto;
  display: block;
  margin-top: 10px;
  margin-left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #70ae6e;
  border-radius: 5px;
  background-color: rgba(56, 152, 236, 0);
  color: #70ae6e;
  font-size: 16px;
  line-height: 10px;
  font-weight: 600;
}

.one-time-payment-submit[disabled] {
  border-color: #cacaca;
  color: #cacaca;
  cursor: auto;
}
</style>
