import { authProvider } from '@/auth/provider';
import { sleepSeconds } from '@/utils/time';

export async function populateLiveChatInfo() {
  // Wait for live chat to be attached to the window, then set the user's name
  // and email. Probably already available, but it's loaded async so we can't be
  // sure. Adding hooks to LiveChat is too much bother, so just pull every
  // 100ms.
  while (!('LiveChatWidget' in window)) {
    await sleepSeconds(0.1);
  }

  const LiveChatWidget = (window as any).LiveChatWidget;

  // If we don't have a user's email, then we have nothing interesting to do
  // here.
  const user = authProvider.user;

  if (!user.email) {
    return;
  }

  LiveChatWidget.call('set_customer_email', user.email);
  if (user.name) {
    LiveChatWidget.call('set_customer_name', user.name);
  }

  // Also try to get the user_id out of the auth token. This shouldn't fail when
  // we have an email present, but we'll continue anyway if it does.
  let user_id = null as string | null;
  try {
    if (authProvider.token) {
      const payload = JSON.parse(window.atob(authProvider.token.split('.')[1]));
      user_id = payload['https://hasura.io/jwt/claims']['x-hasura-user-id'];
    }
  } catch (_e) {
    // Ignore it.
  }

  LiveChatWidget.call('set_session_variables', {
    email_on_account: user.email,
    postgres_user_id: user_id || 'Unknown',
    wrec_user: user_id
      ? `https://wrec.woodriverenergy.com/user/${user_id}`
      : 'none',
  });
}
