import Vue from "vue";

Vue.filter("currency", currency);
Vue.filter("numOrZero", numOrZero);

export function currency(value: any): string {
  const num = Number.parseFloat(value);
  return num.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function numOrZero(value: any): string {
  const num = Number.parseFloat(value);
  if (Number.isNaN(num)) {
    return "0";
  }
  return value;
}
