import { DOMContentLoaded } from '@/utils/events';
import { Auth0ClientOptions } from '@auth0/auth0-spa-js';

const isStage = window.location.hostname.toLowerCase().startsWith('stage.');
const defaultApiUrl = isStage
  ? 'https://hasura-stage.dokku.woodriverenergy.com/v1/graphql/'
  : 'https://hasura-prod.dokku.woodriverenergy.com/v1/graphql/';

// Store API_URL in Session Storage if it's missing.
sessionStorage.setItem(
  'API_URL',
  sessionStorage.getItem('API_URL') || defaultApiUrl
);

/// Config object exposed at the start of each Webflow page (in the <head> tag)
/// to modify per-page attributes like auth requirements. These are the default
/// values for that config.
const defaultPageConfig = {
  /// When set to true, if the user is not already authenticated then they will
  /// be redirected to the login page regardless of it if their auth is actually
  /// used for any backend calls. If this is set to false and a backend call
  /// fails permissions then the user will be redirected to the login page
  /// regardless.
  requiresAuth: false,

  /// A list of plugin names to attach to the application for this page.
  plugins: [],
};

const defaultAppConfig = {
  redirects: [] as {
    pathMatch: RegExp;
    hostname?: string;
    path?: string;
  }[],
};

export const config = {
  apiUrl:
    sessionStorage.getItem('API_URL') ||
    'https://hasura-prod.dokku.woodriverenergy.com/v1/graphql/',
  auth0: {
    clientOptions: {
      domain: 'https://sso.woodriverenergy.com',
      client_id: 'Nl8mpSjbmkcdc1oV6F2dL7AgitK4rVVC',
      redirect_uri: `${window.location.origin}/logged-in`,
      audience: 'https://prod.wre.hasura.io',
      cacheLocation: 'localstorage',
    } as Auth0ClientOptions,
    logoutReturnToUrl: '/',
  },
  defaultPathAfterLogin: '/portal/dashboard',
  appConfig: {
    ...defaultAppConfig,
    ...((window as any).appConfigOverrides || {}),
  } as typeof defaultAppConfig,
};

/// Page config must be fetched after DOMContentLoad because Webflow is free to
/// merge headers in what ever order it sees fit.
export async function getPageConfig() {
  await DOMContentLoaded;
  return {
    ...defaultPageConfig,
    ...((window as any).pageConfigOverrides || {}),
  } as typeof defaultPageConfig;
}

(window as any).config = config;
