import createAuth0Client, { Auth0Client, User } from '@auth0/auth0-spa-js';
import Vue from 'vue';

import { AuthProvider } from '@/auth/provider';
import { config, getPageConfig } from '@/config';
import { logInfo } from '@/logger';
import { getHashParams } from '@/utils/url';

export function initAuth0Provider(): AuthProvider {
  const auth0Promise = createAuth0Client(config.auth0.clientOptions);

  const execLoginWithRedirect = async (redirectUrl: string | null = null) => {
    const auth0 = await auth0Promise;
    await auth0.loginWithRedirect({
      redirect_uri: `${window.location.origin}/logged-in`,
      fragment: redirectUrl
        ? `redirect=${encodeURIComponent(redirectUrl)}`
        : undefined,
    });
  };

  const execLogout = async () => {
    const auth0 = await auth0Promise;
    await auth0.logout({
      returnTo: `${window.origin}${config.auth0.logoutReturnToUrl}`,
    });
  };

  let completeResolver: any = null;
  const authProvider = Vue.observable({
    isCompletePromise: new Promise<void>((res) => (completeResolver = res)),
    auth0Promise,
    isComplete: false,
    isAuthenticated: false,
    isImpersonating: false,
    user: {} as User,
    token: null,
    execLoginWithRedirect,
    execLogout,
  });

  // Handle the /logged-in callback.
  if (window.location.pathname.startsWith('/logged-in')) {
    handleLoginCallback(authProvider);
    return authProvider;
  }

  // Handle the /auth0-login-redirect (used for things like password reset)
  if (window.location.pathname.startsWith('/auth0-login-redirect')) {
    execLoginWithRedirect(null);
    return authProvider;
  }

  // The rest of the flow is handled async.
  void finishLoginFlow(authProvider, completeResolver);
  return authProvider;
}

async function handleLoginCallback(
  provider: AuthProvider & { auth0Promise: Promise<Auth0Client> }
) {
  const auth0 = await provider.auth0Promise;

  try {
    const href = window.location.href;
    const res = await auth0.handleRedirectCallback();
    const redirect = getHashParams('redirect') || config.defaultPathAfterLogin;

    logInfo(`auth0 login callback`, { href, res, redirect });

    // Redirect to originally intended URL.
    window.location.href = redirect;
  } catch (e) {
    window.location.href = '/';
  }
}

async function finishLoginFlow(
  authProvider: AuthProvider & { auth0Promise: Promise<Auth0Client> },
  completeResolver: () => void
) {
  const auth0 = await authProvider.auth0Promise;

  // These throw if they fail, which isn't what we want as they aren't errors.
  try {
    authProvider.isAuthenticated = await auth0.isAuthenticated();
    if (authProvider.isAuthenticated) {
      authProvider.user = (await auth0.getUser()) || {};
      authProvider.token = await auth0.getTokenSilently();
    }
  } catch (_e) {}

  // Finally if we aren't authenticated, but the page requires authentication,
  // then we redirect to the SSO login domain.
  const pageConfig = await getPageConfig();
  if (!authProvider.isAuthenticated && pageConfig.requiresAuth) {
    await authProvider.execLoginWithRedirect(window.location.href);
  }

  authProvider.isComplete = true;
  completeResolver();
}
