import Vue from 'vue';
import { User } from '@auth0/auth0-spa-js';

import { AuthProvider } from '@/auth/provider';

const WRE_JWT_OVERRIDE = 'WRE_JWT_OVERRIDE';

export function getJwtOverride(): string | null {
  const params = new URLSearchParams(location.search);
  return params.get('jwt') || sessionStorage.getItem(WRE_JWT_OVERRIDE);
}

export function initJwtOverrideProvider(jwt: string): AuthProvider {
  // Save the JWT to session storage to handle navigation.
  sessionStorage.setItem(WRE_JWT_OVERRIDE, jwt);

  const execLogout = async () => {
    // Remove the session storage JWT
    sessionStorage.removeItem(WRE_JWT_OVERRIDE);

    // Clear out the URL params
    const params = new URLSearchParams(location.search);
    params.delete('jwt');
    window.location.search = params.toString();
  };

  const authProvider = Vue.observable({
    isCompletePromise: Promise.resolve(),
    isComplete: true,
    isAuthenticated: true,
    isImpersonating: true,
    user: {
      name: 'User Impersonation',
      nickname: 'User Impersonation',
      email: 'dev@woodriverenergy.com',
      picture:
        'https://wre-webflow-apps.s3.us-west-2.amazonaws.com/warning.png',
    } as User,
    token: jwt,
    execLoginWithRedirect: async () => {},
    execLogout,
  });

  return authProvider;
}
